.video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin: auto;
}
.label{
  font-size: 20px;
}
.title {
  position: relative;
}
.title {
  margin: 0;
  align-self: stretch;
  font-size: 30px;
  font-weight: 300;
  font-family: inherit;
  display: inline-block;
  height: 50px;
  flex-shrink: 0;
}
.trans {
  font-size: 16px;
  font-weight: 100;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  flex-shrink: 0;
  white-space: pre-wrap;
}
.label {
  cursor: pointer;
  font-weight: 400;
}
.textInput {
  border: 0;
  background-color: transparent;
}
.submitButton,
.textInput {
  flex: 1;
  position: relative;
}
.submit {
  border-radius: 10px;
  background-color: #ffffff;
  width: 80px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.input,
.output {
  align-self: stretch;
}

.input {
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-31xl);
  font-size: 32px;
}
.output {
  flex: 1;
}
.mygpt {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 1440px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 50px 80px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
  text-align: left;
  font-size: 40px;
  color: var(--color-black);
  font-family: var(--font-inter);
}
