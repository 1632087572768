@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* Colors */
  --color-black: #000;

  /* Gaps */
  --gap-31xl: 20px;

  /* Paddings */
  --padding-xl: 20px;
}
